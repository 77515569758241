<template>
	<v-data-table
		:items="items"
		:headers="headers"
		fixed-header
		hide-default-footer
		disable-pagination
		disable-sort
	>
		<template v-slot:item.value_1="{ item }">
			<v-checkbox
				v-model="item.value_1"
				:value="item.value_5 ? false : item.value_1"
				:disabled="item.value_5"
			></v-checkbox>
		</template>
		<template v-slot:item.value_2="{ item }">
			<v-checkbox
				v-model="item.value_2"
				:value="item.value_5 ? false : item.value_2"
				:disabled="item.value_5"
			></v-checkbox>
		</template>
		<template v-slot:item.value_3="{ item }">
			<v-checkbox
				v-model="item.value_3"
				:value="item.value_5 ? false : item.value_3"
				:disabled="item.value_5"
			></v-checkbox>
		</template>
		<template v-slot:item.value_4="{ item }">
			<v-checkbox
				v-model="item.value_4"
				:value="item.value_5 ? false : item.value_4"
				:disabled="item.value_5"
			></v-checkbox>
		</template>
		<template v-slot:item.value_5="{ item }">
			<v-checkbox
				v-model="item.value_5"
			></v-checkbox>
		</template>
	</v-data-table>
</template>

<script>
export default {
	name: 'MatrixCheckbox',
	props: {
		items: Array,
		headers: Array
	}
}

</script>