var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"fixed-header":"","hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.value_1",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item.value_5 ? false : item.value_1,"disabled":item.value_5},model:{value:(item.value_1),callback:function ($$v) {_vm.$set(item, "value_1", $$v)},expression:"item.value_1"}})]}},{key:"item.value_2",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item.value_5 ? false : item.value_2,"disabled":item.value_5},model:{value:(item.value_2),callback:function ($$v) {_vm.$set(item, "value_2", $$v)},expression:"item.value_2"}})]}},{key:"item.value_3",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item.value_5 ? false : item.value_3,"disabled":item.value_5},model:{value:(item.value_3),callback:function ($$v) {_vm.$set(item, "value_3", $$v)},expression:"item.value_3"}})]}},{key:"item.value_4",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item.value_5 ? false : item.value_4,"disabled":item.value_5},model:{value:(item.value_4),callback:function ($$v) {_vm.$set(item, "value_4", $$v)},expression:"item.value_4"}})]}},{key:"item.value_5",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.value_5),callback:function ($$v) {_vm.$set(item, "value_5", $$v)},expression:"item.value_5"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }