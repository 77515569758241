<template>
	<div>
		<v-row class="mot-survey-form-large">
			<v-col>
				<p class="text-subtitle-1 text-left">以下には司書課程で学ぶことや一部の司書課程で学んでいることを示しています。<br/>あなたが司書課程で学び、「興味関心を惹かれたこと」や「現在の仕事に役立ったと思われること」、司書課程ではあまり学ばなかったと思うが、「興味関心を惹かれること」や「現在の仕事のために学びたかったこと」があれば、全てお選びください。いずれも該当しない場合は「あてはまるものはない」だけをお選びください。【4カテゴリ・40問】＜複数選択＞<span class="star">*</span></p>
			</v-col>
		</v-row>
		<v-row>
			<v-col><h2>図書館の基礎（11問）</h2></v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<MatrixCheckbox
					:items="library_basics"
					:headers="headers"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col><h2>図書館サービス（9問）</h2></v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<MatrixCheckbox
					:items="library_service"
					:headers="headers"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col><h2>図書館情報資源（8問）</h2></v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<MatrixCheckbox
					:items="library_information"
					:headers="headers"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col><h2>選択科目・その他（12問）</h2></v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<MatrixCheckbox
					:items="library_others"
					:headers="headers"
				/>
			</v-col>
		</v-row>
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 0em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 0em;
}

.mot-survey-form {
	margin-top: 1em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import MatrixCheckbox from '@/components/demo/MatrixCheckbox'

export default {
	name: 'UserSurvey',
	components: {
		MatrixCheckbox
	},
	data () {
		return {
			form: {
				motivation: null,
				certificate_year: null
			},
			rules: {
				required: value => !!value || '必須'
			},
			value_5: false,
			motivations: [
				{ text: "他の科目の履修を犠牲にしてでも、司書資格を取得するつもりであった", value: 1 },
				{ text: "他に取りたい資格や優先したいことがなければ、司書資格を取得するつもりであった", value: 2 },
				{ text: "授業が面白くなければ／課題が大変なら、途中で資格取得はやめるかもしれなかった", value: 3 },
				{ text: "とりあえず課程登録はしたが、司書資格を取得する気はあまりなかった", value: 4 },
			],
			certificate_years: [
				{ text: "1970年代", value: 1 },
				{ text: "1980年代", value: 2 },
				{ text: "1990年代", value: 3 },
				{ text: "2000年代", value: 4 },
				{ text: "2010年代", value: 5 },
				{ text: "2020年代", value: 6 },
			],
			headers: [
				{ text: "ID", value: "id" },
				{ text: "学びの内容", value: "text" },
				{ text: "司書課程で学び、興味関心を惹かれた", value: "value_1" },
				{ text: "司書課程で学び、仕事に役立ったと思う", value: "value_2" },
				{ text: "司書課程ではあまり学ばなかったと思うが、興味関心を惹かれる", value: "value_3" },
				{ text: "司書課程ではあまり学ばなかったと思うが、仕事のために学びたかった", value: "value_4" },
				{ text: "あてはまるものはない", value: "value_5" }
			],
			library_basics: [
				{ id: 1, text: "図書や図書館の歴史", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 2, text: "国内の図書館の実態（先進的な実践事例や参考となる取組）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 3, text: "海外の図書館の実態（先進的な実践事例や参考となる取組）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 4, text: "図書館の意義や理念", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 5, text: "さまざまな館種の知識（学校図書館・大学図書館・専門図書館など）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 6, text: "生涯学習に関する知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 7, text: "法律の基礎的な知識（図書館法・社会教育法・個人情報保護法など）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 8, text: "著作権・知的財産権・ライセンスなどの知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 9, text: "地方公共団体における図書館政策や事業計画など", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 10, text: "図書館の事業評価（PDCAサイクルなど）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 11, text: "図書館の運営形態（直営・指定管理・業務委託など）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false }
			],
			library_service: [
				{ id: 12, text: "児童サービスの知識（乳幼児・ヤングアダルトを含む）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 13, text: "高齢者サービスの知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 14, text: "障害者サービスの知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 15, text: "イベント・展示の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 16, text: "接遇・クレーム対応の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 17, text: "図書館と地域の組織・団体・ボランティアの折衝・協力・コミュニケーション", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 18, text: "レファレンスツール（参考図書やデータベース）の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 19, text: "レファレンスツールを用いた探索技術", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 20, text: "利用者の情報ニーズを把握するための知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false }
			],
			library_information: [
				{ id: 21, text: "紙の情報資源を収集するための知識、出版流通の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 22, text: "紙の情報資源を管理するための知識（装備・修理・保存）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 23, text: "紙の情報資源を選定するための知識（選書、蔵書構築）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 24, text: "電子書籍・電子ジャーナル及び収集管理するための知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 25, text: "目録の知識・技術", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 26, text: "分類の知識・技術", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 27, text: "コンピュータなど情報通信機器に関する基礎的な知識・技術（Word、Excelなど）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 28, text: "コンピュータなど情報通信機器に関する応用的な知識・技術（プログラミング、図書館システムなど）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false }
			],
			library_others: [
				{ id: 29, text: "図書館の実務（図書館実習などで一定期間実務を経験する）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 30, text: "情報リテラシー教育（図書館ガイダンス、学習支援など）の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 31, text: "地域資料や古文書の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 32, text: "デジタルアーカイブや学術情報流通（オープンアクセス、オープンサイエンスなど）の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 33, text: "研究者が行う研究活動支援の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 34, text: "ネットワーク・システムの導入及び運用・管理法など", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 35, text: "図書館の建設・改築等に関する知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 36, text: "図書館の予算・会計・契約に関する知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 37, text: "統計の基礎知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 38, text: "プレゼンテーションの経験（ブックトークなどを含む）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 39, text: "グループワークの経験", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 40, text: "その他（ご自由にお書き下さい）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false }
			]
		}
	},
	async created () {
		this.setLoading(true)
		this.clearScaleScore()
		const scaleId = this.test.scales[0].id
		await this.getScale(scaleId).then(() => {
			this.clearScaleItemAnswers()
			this.clearAnsweredScales()
			this.setScaleItemNotAnswered(this.scale.scaleitemorders)
			this.setLoading(false)
			this.scaleitemorders = this.scale.scaleitemorders
			this.startAt = Date.now()
		}).catch(err => {
			this.clearScale()
			this.error = true
			this.setLoading(false)
			console.error(err)
		})
	},
	computed: {
		...mapGetters({
			test: 'stateTest',
			scale: 'stateScale',
			scaleItemAnswered: 'stateScaleItemAnswered',
			scaleItemNotAnswered: 'stateScaleItemNotAnswered',
			scaleItemAnswers: 'stateScaleItemAnswers',
			answeredScales: 'stateAnsweredScales',
		}),
		testId() {
			return this.$route.params.test_id
		},
		scaleLength() {
			return this.scaleitemorders.length
		},
		notAnsweredId() {
			return this.getNotAnsweredId()
		}
	},
	methods: {
		...mapActions([
			'clearScale',
			'getScale',
			'clearScaleItemAnswers',
			'setScaleItemNotAnswered',
			'postScaleItemAnswers',
			'setLoading',
			'setAlert',
			'setAnsweredScales',
			'appendAnsweredScale',
			'clearAnsweredScales',
			'clearScaleItemAnswers',
			'createScaleScore',
			'clearScaleScore',
		]),
		async answer() {
			this.setLoading(true)
			const scaleScore = await this.createScaleScore(
				{
					test_id: this.testId,
					user_id: this.userId,
					testanswer_id: this.testAnswer.id
				}
			)
			const payload = {
				testId: this.testId,
				userId: this.userId,
				startAt: this.startAt,
				scaleScoreId: scaleScore.id
			}
			await this.postScaleItemAnswers(payload).then(() => {
				this.setLoading(false)
				this.appendAnsweredScale(this.scale.id)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		getNotAnsweredId() {
			return this.scaleItemNotAnswered
		}
	}
}
</script>